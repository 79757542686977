const handleTouchStart = (event) => {
    // Add class "touching" to polaroid-clothline__photo
    // to simulate the hover effect

    // Remove class "touching" from all other polaroid-clothline__photo
    document.querySelectorAll(".polaroid-clothline__photo").forEach((photo) => {
        photo.classList.remove("touching");
    });

    // If the event target is not the .polariod-clothline__photo
    // then get the parent element
    const target = event.target.classList.contains("polaroid-clothline__photo") ? event.target : event.target.parentElement;
    target.classList.add("touching");
}


document.querySelectorAll(".polaroid-clothline__photo").forEach((photo) => {
    // If is touch device add touch event to the polaroid photos in the clothline
    if ("ontouchstart" in document.documentElement) {
        photo.addEventListener("touchstart", handleTouchStart, false);
    }
});

// Add event listener to the nav burger and toggle everything for the animations to happen
document.querySelector(".nav__burger").addEventListener("click", () => {
    document.querySelector(".nav__list").classList.toggle("nav__list--open");
    document.querySelector(".nav__burger").classList.toggle("nav__burger--open");
    document.querySelector(".nav__background").classList.toggle("nav__background--open");
});
